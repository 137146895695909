
import React, { Fragment } from 'react'
import moment from 'moment';
import { ToastProvider } from './ToastNotification';
import { FaSpinner } from 'react-icons/fa';
import ComboGeneriqueClass from './ComboGenerique';
import { createPortal } from 'react-dom';
import CONFIG from './config';

class FicheFichierParLotClass extends React.Component {

    constructor(props) {
        super(props)


        this.state = {
            tblFichierAModifier: JSON.parse(JSON.stringify(this.props.tblData))
        }

        this.submitForm = this.submitForm.bind(this);


    }



    submitForm(e) {
        e.preventDefault();
        let _this = this;
        let data = this.state.tblFichierAModifier;

        data.map((element, i) => {
            fetch(CONFIG.URL +'/expertise/putFichier', {  headers: { 'Token': this.props.token },method: "PUT", body: JSON.stringify(element) })
                .then(res => res.json())
                .then(
                    (data) => {
                        if (_this.props.onValide) {
                            _this.props.onValide(JSON.parse(data))
                        }
                        $('#' + 'fichierModal').modal("hide");

                    }
                )


        });

    }




    render() {
        let _this = this;


        if (this.state.tblFichierAModifier) {
            if (this.state.tblFichierAModifier.length == 1) {
                let element = this.state.tblFichierAModifier[0];
                return createPortal(
                    <div>
                        <div key={"ModifParLot"} className="modal fade show" style={{ display: 'block' }} id="fichierModal" tabIndex="-1" aria-labelledby="fichierModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-xl">
                                <div className="modal-content">
                                    <form onSubmit={this.submitForm}>
                                        <div className="modal-header modal-colored-header bg-primary">
                                            <h4 className="modal-title" id="fichierModal">{"Modification du fichier " + element.fiNom}</h4>
                                            <button onClick={this.props.onClosed} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">
                                            <div className='row' >

                                                <div className="col-lg-6">
                                                    <label className="form-label">Nature du fichier</label>
                                                    <ComboGeneriqueClass token={this.props.token} defaultValue={"<< Aucune >>"} fichier={"NatureFichier"} clefiltre={0} name="NatureFichier" value={element.fiNature || ''} onChange={(valeur) => _this.setState({ tblFichierAModifier: [{ ..._this.state.tblFichierAModifier[0], fiNature: valeur }] })}></ComboGeneriqueClass>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="form-label">Provenance/Destinataire</label>
                                                    <ComboGeneriqueClass token={this.props.token} defaultValue={"<< Aucun >>"} fichier={"TiersParExpertise"} clefiltre={_this.props.exid} name="TiersParExpertise" value={(element.tiers && element.tiers.ctId > 0) ? element.tiers.ctId : element.ctIdFi || ''} onChange={(valeur) => _this.setState({ tblFichierAModifier: [{ ..._this.state.tblFichierAModifier[0], ctIdFi: valeur }] })}></ComboGeneriqueClass>
                                                </div>

                                                <div className="col-lg-6">
                                                    <label className="form-label">Date de reception</label>
                                                    <input onChange={(e) => _this.setState({ tblFichierAModifier: [{ ..._this.state.tblFichierAModifier[0], fiCreation: e.target.value }] })} type="date" value={element.fiCreation ? moment(element.fiCreation).format("yyyy-MM-DD") : ''} className="form-control"></input>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="form-label">Date de la pièce</label>
                                                    <input onChange={(e) => _this.setState({ tblFichierAModifier: [{ ..._this.state.tblFichierAModifier[0], fiDatePiece: e.target.value }] })} type="date" value={element.fiDatePiece ? moment(element.fiDatePiece).format("yyyy-MM-DD") : ''} className="form-control"></input>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="form-label">Référence</label>
                                                    <input maxLength={100} onChange={(e) => _this.setState({ tblFichierAModifier: [{ ..._this.state.tblFichierAModifier[0], fiReference: e.target.value }] })} type="text" value={element.fiReference || ''} className="form-control"></input>
                                                </div>

                                                <div className="col-lg-6 mt-4 d-flex">
                                                    <div className="form-check form-switch">
                                                        <input type="checkbox" className="form-check-input" onChange={(e) => _this.setState({ tblFichierAModifier: [{ ..._this.state.tblFichierAModifier[0], fiEstVisible: e.target.checked }] })} checked={element.fiEstVisible || false}></input>
                                                        <label className="form-check-label" >Sur la rapport</label>
                                                    </div>
                                                    <div className="form-check form-switch mx-2">
                                                        <input type="checkbox" className="form-check-input" onChange={(e) => _this.setState({ tblFichierAModifier: [{ ..._this.state.tblFichierAModifier[0], fiEstChronologie: e.target.checked }] })} checked={element.fiEstChronologie || false}></input>
                                                        <label className="form-check-label" >Sur la chronologie</label>
                                                    </div>
                                                    <div className="form-check form-switch">
                                                        <input type="checkbox" className="form-check-input" onChange={(e) => _this.setState({ tblFichierAModifier: [{ ..._this.state.tblFichierAModifier[0], fiEstDeroule: e.target.checked }] })} checked={element.fiEstDeroule || false}></input>
                                                        <label className="form-check-label" >Sur le déroulement</label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label className="form-label">Notes</label>
                                                    <textarea rows={8} onChange={(e) => _this.setState({ tblFichierAModifier: [{ ..._this.state.tblFichierAModifier[0], fiNotes: e.target.value }] })} type="text" value={element.fiNotes || ''} className="form-control"></textarea>
                                           
                                                </div>

                                            </div>
                                        </div>

                                        <div className="modal-footer d-flex">
                                            <button onClick={this.props.onClosed} type="button" className="ml-auto p-2 btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                                            <button type="submit" className="p-2 btn btn-primary">Enregistrer</button>
                                        </div>
                                    </form >
                                </div>



                            </div >

                        </div > <div className="modal-backdrop fade show"></div></div >
                    , document.getElementById('popup'))
            } else {
                return createPortal(
                    <div>
                        <div key={"ModifParLot"} className="modal fade show" style={{ display: 'block' }} id="fichierModal" tabIndex="-1" aria-labelledby="fichierModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-xl" style={{minWidth: '90%'}} >
                                <div className="modal-content">
                                    <form onSubmit={this.submitForm}>
                                        <div className="modal-header modal-colored-header bg-primary">
                                            <h4 className="modal-title" id="fichierModal">Modification par lot</h4>


                                            <button onClick={this.props.onClosed} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>

                                        <div className="FichierParLot modal-body">
                                            <table className="table  table-sm" id="main-table">

                                                <thead className="table-dark">
                                                    <tr>
                                                        <th style={{ width: '15%' }}>Fichier</th>
                                                        <th style={{ width: '22%' }}>Nature
                                                        <ComboGeneriqueClass token={this.props.token} defaultValue={"<< Par lot >>"} fichier={"NatureFichier"} clefiltre={0} name="NatureFichier" value={''} onChange={(valeur) => 
                                                                       _this.setState({ tblFichierAModifier: _this.state.tblFichierAModifier.map(ele => ({...ele,fiNature: valeur})) })                                                                   
                                                                    
                                                                    }></ComboGeneriqueClass></th>
                                                        <th style={{ width: '22%' }}>Provenance 
                                                         <ComboGeneriqueClass token={this.props.token} defaultValue={"<< Par lot >>"} fichier={"TiersParExpertise"} clefiltre={_this.props.exid} name="TiersParExpertise" value={''} onChange={(valeur) => 
                                                                       _this.setState({ tblFichierAModifier: _this.state.tblFichierAModifier.map(ele => ({...ele,ctIdFi: valeur,tiers:{}})) })                                                                   
                                                                    
                                                                    }></ComboGeneriqueClass></th>
                                                        <th style={{ width: '10%' }}>Date de création
                                                        <input onChange={(e) => {
                                                                          _this.setState({ tblFichierAModifier: _this.state.tblFichierAModifier.map(ele => ({...ele,fiCreation: e.target.value})) })                                                                   
                                                                   
                                                                    }} type="date" defaultValue={''} className="form-control" name="fiCreation"></input>
                                                        </th>
                                                        <th style={{ width: '10%' }}>Date de pièce
                                                        <input onChange={(e) => {
                                                                          _this.setState({ tblFichierAModifier: _this.state.tblFichierAModifier.map(ele => ({...ele,fiDatePiece: e.target.value})) })                                                                   
                                                                   
                                                                    }} type="date" defaultValue={''} className="form-control" name="fiDatePiece"></input>
                                                        </th>
                                                        <th style={{ width: '16%' }}>Référence</th>
                                                        <th style={{ width: '5%' }}>Sur le <br /> rapport
                                                        <input onChange={(e) => 
                                                        _this.setState({ tblFichierAModifier: _this.state.tblFichierAModifier.map(ele => ({...ele,fiEstVisible: e.target.checked})) })                                                                   
                                                        } type="checkbox" defaultValue={true} className="" name="fiEstVisible"></input>
                                                        </th>
                                                        <th style={{ width: '5%' }}>Sur la <br /> chronologie
                                                        <input onChange={(e) => 
                                                        _this.setState({ tblFichierAModifier: _this.state.tblFichierAModifier.map(ele => ({...ele,fiEstChronologie: e.target.checked})) })                                                                   
                                                        } type="checkbox" defaultValue={false} className="" name="fiEstVisible"></input>
                                                        </th>
                                                        <th style={{ width: '5%' }}>Sur le <br /> déroulement
                                                        <input onChange={(e) => 
                                                        _this.setState({ tblFichierAModifier: _this.state.tblFichierAModifier.map(ele => ({...ele,fiEstDeroule: e.target.checked})) })                                                                   
                                                        } type="checkbox" defaultValue={false} className="" name="fiEstVisible"></input>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        _this.state.tblFichierAModifier.map((element, i) => {


                                                            return (
                                                                <tr className="FichierParLot" key={"FiLigne" + i} id={i}>
                                                                    <td>{element.fiNom}</td>
                                                                    <td><ComboGeneriqueClass token={this.props.token} defaultValue={"<< Aucune >>"} fichier={"NatureFichier"} clefiltre={0} name="NatureFichier" value={element.fiNature || ''} onChange={(valeur) => {
                                                                        let dataLocal = _this.state.tblFichierAModifier;
                                                                        dataLocal[i].fiNature = valeur;

                                                                        _this.setState({ tblFichierAModifier: dataLocal })
                                                                    }
                                                                    }></ComboGeneriqueClass></td>
                                                                    <td><ComboGeneriqueClass token={this.props.token} defaultValue={"<< Aucun >>"} fichier={"TiersParExpertise"} clefiltre={_this.props.exid} name="TiersParExpertise" value={(element.tiers && element.tiers.ctId > 0) ? element.tiers.ctId : element.ctIdFi || ''} onChange={(valeur) => {
                                                                        let dataLocal = _this.state.tblFichierAModifier;
                                                                        dataLocal[i].ctIdFi = valeur;
                                                                        dataLocal[i].tiers = {};
                                                                        _this.setState({ tblFichierAModifier: dataLocal })
                                                                    }
                                                                    }></ComboGeneriqueClass></td>
                                                                    <td><input onChange={(e) => {
                                                                        let dataLocal = _this.state.tblFichierAModifier;
                                                                        dataLocal[i].fiCreation = e.target.value;
                                                                        _this.setState({ tblFichierAModifier: dataLocal })

                                                                    }} type="date" value={element.fiCreation ? moment(element.fiCreation).format("yyyy-MM-DD") : ''} className="form-control" name="fiCreation"></input></td>
                                                                    <td><input onChange={(e) => {
                                                                        let dataLocal = _this.state.tblFichierAModifier;
                                                                        dataLocal[i].fiDatePiece = e.target.value;
                                                                        _this.setState({ tblFichierAModifier: dataLocal })

                                                                    }} type="date" value={element.fiDatePiece ? moment(element.fiDatePiece).format("yyyy-MM-DD") : ''} className="form-control" name="fiCreation"></input></td>

                                                                    <td><input onChange={(e) => {
                                                                        let dataLocal = _this.state.tblFichierAModifier;
                                                                        dataLocal[i].fiReference = e.target.value;
                                                                        _this.setState({ tblFichierAModifier: dataLocal })

                                                                    }} type="text" maxLength={100} value={element.fiReference} className="form-control" name="fiReference"></input></td>
                                                                    <td><input onChange={(e) => {

                                                                        let dataLocal = _this.state.tblFichierAModifier;
                                                                        dataLocal[i].fiEstVisible = e.target.checked;
                                                                        _this.setState({ tblFichierAModifier: dataLocal })

                                                                    }} type="checkbox" checked={element.fiEstVisible} className="" name="fiEstVisible"></input></td>
                                                                    <td><input onChange={(e) => {

                                                                        let dataLocal = _this.state.tblFichierAModifier;
                                                                        dataLocal[i].fiEstChronologie = e.target.checked;
                                                                        _this.setState({ tblFichierAModifier: dataLocal })

                                                                    }} type="checkbox" checked={element.fiEstChronologie} className="" name="fiEstChronologie"></input></td>
                                                                    <td><input onChange={(e) => {

                                                                        let dataLocal = _this.state.tblFichierAModifier;
                                                                        dataLocal[i].fiEstDeroule = e.target.checked;
                                                                        _this.setState({ tblFichierAModifier: dataLocal })

                                                                    }} type="checkbox" checked={element.fiEstDeroule} className="" name="fiEstDeroule"></input></td>

                                                                </tr>
                                                            )


                                                        }
                                                        )
                                                    }
                                                </tbody>
                                            </table >
                                        </div>
                                        <div className="modal-footer d-flex">
                                            <button onClick={this.props.onClosed} type="button" className="ml-auto p-2 btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                                            <button type="submit" className="p-2 btn btn-primary">Enregistrer</button>
                                        </div>
                                    </form >
                                </div>



                            </div >

                        </div ><div className="modal-backdrop fade show"></div></div>
                    , document.getElementById('popup'))
            }
        }

    }


}

export default FicheFichierParLotClass
