import React, { Fragment } from 'react'
import CONFIG from './config';

class ComboGeneriqueClass extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
           value:this.props.value,
            tblData: []
        }
        this.getData();


    }


    //  this.addForum=this.addForum.bind(this);


    /*
        addForum(IDPARENT) {
            this.props.addForum(IDPARENT)
        }
    */
    componentDidUpdate(prevProps) {

        if (this.props.clefiltre !== prevProps.clefiltre) {
            this.getData();
        }
        if (this.props.value !== prevProps.value) {
           this.setState({value:this.props.value});
        }
    }


    getData() {
        fetch(CONFIG.URL+'/data/getData?fichier=' + this.props.fichier + "&clefiltre=" + this.props.clefiltre,{  headers: { 'Token': this.props.token },cache: "default"})
            //fetch("/getForum/"+this.props.chaFichier+"/"+ this.props.entIDFIC)
            .then(res => res.json())
            .then(
                (result) => {
                
                    this.setState({
                        tblData: JSON.parse(result),

                    });

                },
                (error) => {
                    console.log(error);
                    this.setState({

                        tblData: []
                    });

                }
            )
    }

    defaultValue() {
        if (this.props.defaultValue && this.props.defaultValue!="") {
            return <option key={0} value={0}>{this.props.defaultValue}</option>
        } 
    }

    render() {
        let _this = this;
        return (
            <select key={"CboGenerique"+this.props.name} value={_this.state.value || 0} onChange={(e)=>{_this.setState({value:e.target.value});_this.props.onChange(e.target.value)}} className="form-select col-lg-12" aria-label=".form-select-lg">
                {_this.defaultValue()}
                {_this.state.tblData.map((element, i) =>
                    <option key={"CboGenerique"+this.props.name+"_"+element.cle} value={element.cle}>{element.valeur}</option>
                )}
            </select>)

    }
}

export default ComboGeneriqueClass//withPopup(HistoriqueStatutClass)