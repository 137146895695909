import React, { useState, useEffect, Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { DefaultButton } from "@fluentui/react";
import Header from "./Header";

import CONFIG from "./config.js";
import { ExpertiseGed } from "./ExpertiseGed";
import Progress from "./Progress";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { AiOutlineRollback, AiOutlinePicture, AiOutlineDatabase, AiOutlineWarning } from "react-icons/ai";
import ClassementDossier from "./ClassementDossier";
import { ToastProvider } from "./ToastNotification";
import TableIntervenantParType from "./TableIntervenantParType";
import { ModeleEmail } from "./ModeleEmail";


/* global require */

export const App = (props) => {
    const [token, settoken] = useState("");
    const [tblData, settblData] = useState([]);
    const [IsAuthenticate, setIsAuthenticate] = useState(false);
    const [fonction, setfonction] = useState('');
    const [action, setAction] = useState('Destinataire');

    const [enChargement, setEnChargement] = useState(false);
    const [URL, setURL] = useState("");

    const [exId, setExId] = useState(0);
    const [ErrorMessage, setErrorMessage] = useState(null);

    const { title, isOfficeInitialized } = props;

    useEffect(() => {
        if (URL && URL != '') {
            CONFIG.URL = 'https://' + URL;
            localStorage.setItem("URL", URL);

        }

    }, [URL])

    async function click() {
        /**
         * Insert your Outlook code here
         */
    }

    setTimeout(() => {
        if (Office.context.mailbox && Office.context.mailbox.item && Office.context.mailbox.item.itemId && Office.context.mailbox.item.itemId != '') {
            if (fonction != 'Classement') setfonction('Classement')
        } else {
            if (fonction != 'Envoi') setfonction('Envoi')
        }
    }, 500);


    useEffect(() => {
        if (IsAuthenticate && token != "") {
            getExpertise();
        }
    }, [token, IsAuthenticate]);

    function getExpertise() {
        if (token == "") return;
        fetch(CONFIG.URL + "/expertise/getAll", { headers: { Token: token } })
            .then((res) => res.json())
            .then(
                (result) => {
                    settblData(result);
                },
                (error) => {
                    console.log(error);
                    settblData([]);
                }
            );
    }

    useEffect(() => {

        if (localStorage.getItem("URL") && localStorage.getItem("URL") != "") {
            setURL(localStorage.getItem("URL"))
            CONFIG.URL = 'https://' + localStorage.getItem("URL");
        }

        if (localStorage.getItem("token") && localStorage.getItem("token") != "") {

            fetch(CONFIG.URL + "/auth/getAuth", { method: "GET", headers: { Token: localStorage.getItem("token") } }).then(
                (res) => {

                    if (res.status != 200) {
                        localStorage.setItem("token", "");
                        //      login();
                    } else {
                        res.json().then(
                            (texte) => {

                                if (texte != "OK") {
                                    localStorage.setItem("token", "");
                                } else {
                                    settoken(localStorage.getItem("token"));
                                    setIsAuthenticate(true);
                                }
                            },
                            (error) => {
                                console.log(error)
                                localStorage.setItem("token", "");
                            }
                        );
                    }
                },
                (error) => {
                    console.log(error)
                    localStorage.setItem("token", "");
                    //    login();
                }
            );
        } else {
            //  login();
        }
    }, []);


    function login() {
        var dialog;
        //Office.context.ui.displayDialogAsync('https://localhost:3000/login.html?URL=' + URL, function (asyncResult) {
        Office.context.ui.displayDialogAsync("https://outlook.gexcloud.fr/login.html?URL="+URL, function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                showNotification((asyncResult.error.code = ": " + asyncResult.error.message));
            } else {
                dialog = asyncResult.value;

                dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (arg) {
                    setIsAuthenticate(true);
                    settoken(arg.message);
                    localStorage.setItem("token", arg.message);
                    getExpertise();
                    dialog.close();
                });
            }
        });
    }

    function getAction() {
        switch (action) {
            case "PJ": return <ExpertiseGed modeSelection={true} complet={true} sansTitre={true} token={token} exid={exId}></ExpertiseGed>
            case "Destinataire": return <ToastProvider><TableIntervenantParType token={token} exid={exId}></TableIntervenantParType></ToastProvider>
            case "ModeleEmail": return <ModeleEmail token={token} exid={exId}></ModeleEmail>
        }
    }
    function logout() {
        setIsAuthenticate(false);
        settoken("");
        localStorage.setItem("token", "");
    }

    if (!isOfficeInitialized) {
        return (
            <Progress
                title={title}
                logo={require("./../../../assets/logo-filled.png")}
                message="Merci de recharger le composant"
            />
        );
    }

    if (!IsAuthenticate) {
        return (
            <div>
                <Header logo={require("./../../../assets/logo-filled.png")} title={props.title} message="GEX Online" />
                <div>
                    <div className="h5 p-2">Bienvenue sur le complément Office de GEX Online.</div>
                    <div className="p-2">
                        GEX est la solution de gestion des expertises judiciaires et privées disponible sur le site{" "}
                        <a target="_blank" href={"https://" + URL}>
                            gexcloud.fr
                        </a>
                    </div>
                    <div className="p-2">
                        Utiliser le complément Outlook pour classer les emails et pièces jointes.
                    </div>
                </div>
                <div className="GexContent d-flex">
                    <select onChange={(e) => setURL(e.target.value)} type="text" value={URL} className="form-control">
                        <option value={""}>Choix d'un serveur</option>
                        <option value={"s1.gexcloud.fr"}>Serveur 1</option>
                        <option value={"s2.gexcloud.fr"}>Serveur 2</option>
                        <option value={"s3.gexcloud.fr"}>Serveur 3</option>
                        <option value={"s4.gexcloud.fr"}>Serveur 4</option>
                        <option value={"s5.gexcloud.fr"}>Serveur 5</option>
                        <option value={"s6.gexcloud.fr"}>Serveur 6</option>
                        <option value={"s7.gexcloud.fr"}>Serveur 7</option>
                        <option value={"s8.gexcloud.fr"}>Serveur 8</option>
                        <option value={"s9.gexcloud.fr"}>Serveur 9</option>
                    </select>

                    {URL && URL != "" ? (
                        <DefaultButton style={{ minWidth: '150px' }} className="ms-welcome__action mx-2" iconProps={{ iconName: "ChevronRight" }} onClick={login}>
                            Se connecter
                        </DefaultButton>
                    ) : null}
                </div>
            </div>
        );
    }

    return (
        <div className="ms-welcome">
            {ErrorMessage ? (
                <>
                    <div className="modal fade show" style={{ display: "block" }}>
                        <div style={{ overflowY: "auto" }} className={"modal-dialog modal-lg"}>
                            <div className="modal-content ">
                                <div className={"modal-header modal-colored-header bg-primary"}>
                                    <h4>Erreur</h4>
                                    <button
                                        onClick={() => setErrorMessage(null)}
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body modal-tailleFenetre">
                                    <p>
                                        <AiOutlineWarning style={{ width: "50px" }}></AiOutlineWarning>
                                        {ErrorMessage}
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-primary" onClick={() => setErrorMessage(null)}>
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            ) : null}
            {enChargement ? <Fetching></Fetching> : null}
            <Header logo={require("./../../../assets/logo-filled.png")} title={props.title} message="GEX Online" />

            <div className="GexContent">
                <DefaultButton className="ms-welcome__action" iconProps={{ iconName: "ChevronRight" }} onClick={logout}>
                    Deconnexion
                </DefaultButton>
                {exId <= 0 ? (
                    <div className="m-2">
                        <label className="form-label">
                            <h4>Selectionnez une expertise</h4>
                        </label>
                        <select className="form-control" defaultValue="0" value={exId} onChange={(e) => setExId(e.target.value)}>
                            <option value="0">{"<< Selectionnez une expertise >>"}</option>
                            {tblData
                                ? tblData.map((element, i) => (
                                    <option value={element.exId} key={i}>
                                        {element.exNumero + " - " + element.exNom}
                                    </option>
                                ))
                                : null}
                        </select>
                    </div>
                ) : (
                    <>
                        <DefaultButton className="ms-welcome__action mx-2" iconProps={{ iconName: "ChevronRight" }} onClick={() => setExId(0)}>
                        Changer d'expertise
                </DefaultButton>

                        <div className="mt-2">
                            {fonction == "Classement" ? <ToastProvider><ClassementDossier token={token} exId={exId}></ClassementDossier></ToastProvider> : null}
                            {fonction == "Envoi" ? 
                                <>
                                <div>
                                <button onClick={() => setAction('Destinataire')} className='btn btn-primary my-1'>Destinataires</button>
                                <button onClick={() => setAction('PJ')} className='btn btn-primary my-1 mx-2'>Pièces jointes</button>
                                <button onClick={() => setAction('ModeleEmail')} className='btn btn-primary my-1'>Modele d'email</button>
                          

                                </div>
                                {getAction()}
                                </>
                                
                            
                            
                            : null}
                        </div>

                    </>
                )}
            </div>
        </div>
    );
};


App.propTypes = {
    title: PropTypes.string,
    isOfficeInitialized: PropTypes.bool,
};
