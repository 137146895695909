import React, { useState, useEffect, Fragment } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { GrCheckboxSelected, GrAddCircle, GrRefresh } from 'react-icons/gr';
import { MdExpandMore, MdChevronRight } from 'react-icons/md';
import { FcFolder, FcFile } from 'react-icons/fc';
import PdfViewer from './PdfViewer';
import moment from 'moment';
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import CONFIG from './config'
import Fetching from './Fetching';

export const ExpertiseGed = (props) => {
    const [Loading, setLoading] = useState("");
    const [tblData, settblData] = useState([]);
    const [Menu, setMenu] = useState(null);
    const [tblFichierEnCours, settblFichierEnCours] = useState([]);
    const [tblDossierCharge, settblDossierCharge] = useState([]);
    const [fenSaisie, setfenSaisie] = useState({});
    const [dossierEnCours, setdossierEnCours] = useState({});

    const [tblFichierSelect, settblFichierSelect] = useState([]);
    const [tblDossierSelect, settblDossierSelect] = useState([]);

    const [ApercuFichierPDF, setApercuFichierPDF] = useState('');
    const [ApercuFichierImage, setApercuFichierImage] = useState(null);
    const [AffichageApercu, setAffichageApercu] = useState(false);
    const [enFetching, setenFetching] = useState(false)


    useEffect(() => {
        if (tblData.length <= 0) getData();

    }, []);

    function trclick(dossier, fichier, e) {
        console.log(e);
        if (props.onSelect) {
            props.onSelect(fichier ? fichier.fiId : 0, dossier ? dossier.dsId : 0);
        }
        if (dossier && dossier.dsId) {
            setdossierEnCours(dossier);
            settblFichierEnCours([])

        } else {
            if (fichier) {
                let multiSelection = e.ctrlKey;
                if (multiSelection || (tblFichierEnCours.find(element => element.fiId == fichier.fiId) && e.button == 2)) {
                    if (!(tblFichierEnCours.find(element => element.fiId == fichier.fiId))) {
                        settblFichierEnCours([...tblFichierEnCours, fichier]);
                    } else {
                        if (e.button == 0) {
                            var index = tblFichierEnCours.indexOf(fichier);
                            if (index !== -1) {
                                settblFichierEnCours(tblFichierEnCours.filter((ele, i) => i !== index))
                            }

                        }
                    }
                    setdossierEnCours(null);
                } else {
                    settblFichierEnCours([fichier]);
                    setdossierEnCours(null);
                }
            }
        }

    }

  

    function insertFile() {
        let i = 0;
        setenFetching(true);
        for (i = tblFichierSelect.length - 1; i >= 0; i--) {
            Office.context.mailbox.item.addFileAttachmentAsync(CONFIG.URL + '/expertise/getFichier/' + tblFichierSelect[i].fiId+'?auth='+ props.token,tblFichierSelect[i].fiNom+'.'+tblFichierSelect[i].fichiertype.ftExtention)
           

        }
        settblFichierSelect([]);
        setenFetching(false);
    }

    function fctMiseAjourTblDossier(tblDossier, dsId, tblDossierACharger) {
        let i = 0;
        for (i = 0; i < tblDossier.length; i++) {
            if (tblDossier[i].dsId == dsId) {
                tblDossier[i].sousdossiers = tblDossierACharger;
                return true;
            } else {
                if (fctMiseAjourTblDossier(tblDossier[i].sousdossiers, dsId, tblDossierACharger)) return true;
            }

        }
    }



    function getDataEnfant(dossier, i) {
        fetch(CONFIG.URL + '/expertise/getDossier/' + props.exid + '/' + dossier.dsId, { headers: { 'Token': props.token } })
            .then(res => res.json())
            .then(
                (result) => {
                    let tblDossierLocal = tblData;

                    if (fctMiseAjourTblDossier(tblDossierLocal, dossier.dsId, JSON.parse(result))) {
                        settblData(tblDossierLocal);
                    }
                },
                (error) => {
                    console.log(error);
                }
            )

    }



    function getData() {

        fetch(CONFIG.URL + '/expertise/getDossier/' + props.exid + '/0', { headers: { 'Token': props.token } })

            .then(res => res.json())
            .then(
                (result) => {
                    settblData(JSON.parse(result));
                    setLoading(true);
                },
                (error) => {
                    settblData(null);
                    setLoading(true);
                }
            )
    }


    if (!Loading) {
        return (<div key={"SPNN" + props.exid} className="">
            <FaSpinner className="spinner" />
        </div>)
    }

    function pcdSelectFichier(fichier, select) {
        if (select) {
            if (tblFichierSelect.indexOf(fichier) < 0) {
                settblFichierSelect([...tblFichierSelect, fichier]);
            }
        } else {
            let index = tblFichierSelect.indexOf(fichier);
            if (index !== -1) {
                settblFichierSelect(tblFichierSelect.filter((ele, i) => i !== index))

            }
        }


    }

    function fctGetDossier(dossier, tblDossier, tblFichier, select) {
        let i = 0;
        for (i = 0; i < dossier.sousdossiers.length; i++) {
            if (!select || tblDossierSelect.indexOf(dossier.sousdossiers[i]) < 0) {
                tblDossier.push(dossier.sousdossiers[i])
            }

            fctGetDossier(dossier.sousdossiers[i], tblDossier, tblFichier, select);
        }
        for (i = 0; i < dossier.fichiers.length; i++) {
            if (!select || tblDossierSelect.indexOf(dossier.fichiers[i]) < 0) {

                tblFichier.push(dossier.fichiers[i])
            }
        }
    }


    function pcdSelectDossier(dossier, select) {
        let tblDossier = [dossier];
        let tblFichier = [];

        fctGetDossier(dossier, tblDossier, tblFichier, select);

        if (select) {
            settblDossierSelect([...tblDossierSelect, ...tblDossier]);
            settblFichierSelect([...tblFichierSelect, ...tblFichier]);

        } else {

            settblDossierSelect(tblDossierSelect.filter((ele, i) => tblDossier.indexOf(ele) < 0))
            settblFichierSelect(tblFichierSelect.filter((ele, i) => tblFichier.indexOf(ele) < 0))
        }


    }



    function getFichier(fichier, niveau) {

        return (
            <tr className={tblFichierEnCours.find(element => element.fiId == fichier.fiId) ? 'ligneSelect ligneEnCours' : ''} onMouseDown={(e) => trclick(null, fichier, e)} key={"FI" + fichier.fiId} id={fichier.fiId}>
                {props.modeSelection ? <td><input onChange={(e) => pcdSelectFichier(fichier, e.target.checked)} type="checkbox" checked={tblFichierSelect.find(ele => ele.fiId == fichier.fiId) ? "checked" : ""}></input></td> : null}
                <td style={{ paddingLeft: 40 * niveau + 'px' }}><FcFile /><a href="#" onClick={(e) => {
                    if (fichier.fichiertype.ftExtention == "PDF") {
                        e.preventDefault();
                        setApercuFichierPDF(CONFIG.URL + '/expertise/getFichier/' + fichier.fiId + "?auth=" + props.token)
                    }
                    if (fichier.fichiertype.ftExtention == "DOCX") {
                        e.preventDefault();
                        setApercuFichierPDF(CONFIG.URL + '/data/getPDF/' + fichier.fiId + "?auth=" + props.token)
                    }
                    if (fichier.fichiertype.ftExtention == "XLSX") {
                        e.preventDefault();
                        setApercuFichierPDF(CONFIG.URL + '/data/getPDF/' + fichier.fiId + "?auth=" + props.token)
                    }
                    /*
                    if (fichier.fichiertype.ftExtention == "PNG" || fichier.fichiertype.ftExtention == "JPG" || fichier.fichiertype.ftExtention == "JPEG") {
                        e.preventDefault();
                        setApercuFichierImage(fichier)
                    }*/
                }} >{fichier.fiNom}</a></td>
                <td className='hidden-md'>{fichier.fichiertype.ftNom}</td>
                <td className='hidden-xs'>{moment(Date.parse(fichier.fiCreation)).format("DD/MM/YYYY")}</td>
                <td className='hidden-md'>{fichier.fiDatePiece ? moment(Date.parse(fichier.fiDatePiece)).format("DD/MM/YYYY") : ''}</td>
                <td className='hidden-xs'>{fichier.tiers ? (fichier.tiers.ctEstPro ? fichier.tiers.ctIntitule : fichier.tiers.ctNom + ' ' + fichier.tiers.ctPrenom) : ''}</td>
                <td className='hidden-xs'>{fichier.fiNature}</td>
                <td className='hidden-md'>{fichier.fiReference}</td>
                <td className='hidden-md'><input readOnly={"readonly"} type="checkbox" checked={fichier.fiEstVisible}></input></td>

            </tr>
        )
    }
    function getDossier(element, niveau) {
        return <Fragment key={"DS" + element.dsId}>
            <tr className={dossierEnCours == element ? 'ligneSelect ligneEnCours' : ''} onMouseDown={(e) => trclick(element, null, e)} id={element.dsId} key={"DS" + element.dsId}>
                {props.modeSelection ? <td><input onChange={(e) => pcdSelectDossier(element, e.target.checked)} type="checkbox" checked={tblDossierSelect.indexOf(element) >= 0}></input></td> : null}
                <td style={{ paddingLeft: (!asChild(element) && niveau == 0) ? 20 : 40 * niveau - (asChild(element) ? 15 : 0) + 'px' }}>{asSousDossier(element)}<FcFolder /><span>{element.dsNom}</span> </td>
                {props.complet ?
                    <>
                        <td className='hidden-md'>{""}</td>
                        <td className='hidden-xs'>{""}</td>
                        <td className='hidden-md'>{""}</td>
                        <td className='hidden-xs'>{""}</td>
                        <td className='hidden-xs'>{""}</td>
                        <td className='hidden-md'>{""}</td>
                        <td className='hidden-md'>{""}</td>
                    </>
                    : null}
            </tr>
            {tblDossierCharge.indexOf(element.dsId) >= 0 ? element.sousdossiers.map((souselement, i) => {
                return (
                    getDossier(souselement, niveau + 1)
                )
            }) : null}
            {tblDossierCharge.indexOf(element.dsId) >= 0 && props.complet ? element.fichiers.map((souselement, i) => {
                if (props.filtreExtension && props.filtreExtension.length > 0 && props.filtreExtension.findIndex((e) => e == souselement.fichiertype.ftExtention) < 0) {
                    null
                } else {
                    return getFichier(souselement, niveau + 1)

                }


            }) : null}
        </Fragment>

    }

    function asChild(dossier) {

        if ((dossier.sousdossiers && dossier.sousdossiers.length > 0) || (props.complet && dossier.fichiers && dossier.fichiers.length > 0)) {
            return true;
        } else {
            return false;
        }
    }


    function asSousDossier(dossier) {
        if ((dossier.sousdossiers && dossier.sousdossiers.length > 0) || (props.complet && dossier.fichiers && dossier.fichiers.length > 0)) {
            return (<a href="#" onClick={(e) => chargeSousDossier(e, dossier)}>{tblDossierCharge.indexOf(dossier.dsId) >= 0 ? <MdExpandMore /> : <MdChevronRight />}</a>);
        }
    }

    function chargeSousDossier(e, dossier) {
        e.preventDefault();

        var index = tblDossierCharge.indexOf(dossier.dsId);
        if (index >= 0) {

            settblDossierCharge(tblDossierCharge.filter((ele, i) => i !== index))

            return;
        }
        settblDossierCharge([...tblDossierCharge, dossier.dsId])
        if (dossier.sousdossiers && dossier.sousdossiers.length > 0) {
            dossier.sousdossiers.map((element, i) => {
                getDataEnfant(element, i);
            })
        }
    }


    return (<div id="tahFichier" className="col-lg-12" key={"GED" + props.exid}>
        {enFetching ? <div>
            <Fetching></Fetching>
        </div> : null}
        <div className=' card shadow p-2'>
            <div>
                <button onClick={insertFile} style={{ display: (tblFichierSelect && tblFichierSelect.length > 0 ? '' : 'none') }} className='btn btn-primary my-1'>Insérer</button>
            </div>
            <table className="table table-sm" id="main-table">

                <thead className="table-dark">
                    <tr>
                        {props.modeSelection ? <th style={{ width: '5%' }}><GrCheckboxSelected /></th> : null}
                        <th style={{ width: '30%' }}>Nom</th>
                        <th className='hidden-md' style={{ width: '15%' }}>Format</th>
                        <th className='hidden-xs' style={{ width: '10%' }}>Créé le</th>
                        <th className='hidden-md' style={{ width: '10%' }}>Date de pièce</th>
                        <th className='hidden-xs' style={{ width: '10%' }}>Provenance/Destinataire</th>
                        <th className='hidden-xs' style={{ width: '10%' }}>Type</th>
                        <th className='hidden-md' style={{ width: '10%' }}>Référence</th>
                        <th className='hidden-md' style={{ width: '5%' }}>Sur le <br /> rapport</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tblData.map((element, i) =>
                            getDossier(element, 0)


                        )
                    }

                </tbody>
            </table >
            {ApercuFichierPDF != '' ? <PdfViewer url={ApercuFichierPDF} onClose={() => setApercuFichierPDF('')}></PdfViewer> : null}

        </div>
    </div >

    )

}

