import React from 'react'
import { useToasts } from './ToastNotification';
import GedDossierClass from './GedDossier'
import { Fragment } from 'react'
import FicheFichierParLotClass from './FicheFichierParLot'
import CONFIG from './config'
import moment from 'moment'

function withToast(Component) {
    return function WrappedComponent(props) {
        const addToast = useToasts()
        return <Component {...props} {...addToast} />;
    }
}


//import DndClassement from './DndClassement'

class ClassementDossierClass extends React.Component {

    constructor(props) {
        super(props)


        this.state = {
            tblData: null,
            tblFichier: [],
            tblDataFichierParLot: [],
            booArborescence: true,
            dossierEnCours: this.props.dossier ? this.props.dossier : {},
            tblPJ: Office.context.mailbox.item.attachments,

            exId: this.props.exId
        }

        this.postclassement = this.postclassement.bind(this);
        this.selectDossier = this.selectDossier.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.getData = this.getData.bind(this);
        this.ModificationParLot = this.ModificationParLot.bind(this);
        this.onLoad();
        this.email = Office.context.mailbox.item;

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.tblDataFichierParLot && this.state.tblDataFichierParLot.length > 0 && !this.state.openPopupFichierParLot && this.state.tblDataFichierParLot.length == this.state.tblPJ.filter(ele => ele.aClasser == true).length) {
            this.setState({ openPopupFichierParLot: true })
        }
        if (!this.state.tblPJ || this.state.tblPJ.length <= 0) return;
        if (this.state.tblPJ.find(ele => ele.contenu != null)) return;
        for (let z = 0; z < this.state.tblPJ.length; z++) {
            let element = this.state.tblPJ[z];
            if (!element.isInline) continue
            this.email.getAttachmentContentAsync(element.id, (valueElement) => {
                this.setState({ tblPJ: this.state.tblPJ.map(ele => ele == element ? { ...ele, contenu: 'data:' + element.contentType + ';base64,' + valueElement.value.content } : ele) })

            });
        }
    }

    onLoad() {
        if (this.props.exId > 0) {
            this.getData(this.props.exId);
        }
    }


    getData(exId) {
        fetch(CONFIG.URL + '/expertise/getDossier/' + exId + '/0', { headers: { 'Token': this.props.token } })
            //fetch("/getForum/"+this.props.chaFichier+"/"+ this.props.entIDFIC)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({ tblData: JSON.parse(result), Loading: true });
                },
                (error) => {
                    console.log(error);
                    this.setState({ tblData: null, Loading: true });
                }
            )
    }


    postclassement() {
        let _this = this;
        let email = {
            expediteur: this.email.from.emailAddress,
            expediteurNom: this.email.from.displayName,
            IdExterne: this.email.itemId,
            dhReception: this.email.dateTimeCreated,
            subject: this.email.subject,
            fmType: (Office.context.mailbox.initialData.userEmailAddress==this.email.from.emailAddress ) ? 2 : 1,
            isHtml: true,
            exIdFm: this.props.exId,
            nbPj: this.email.attachments.length,
            to: this.email.to,
            cc: this.email.cc
        }
        console.log(this.email)
        this.email.body.getAsync(
            "html",
            { asyncContext: email },
            function callback(result) {
                let body = result.value;
                for (let z = 0; z < _this.state.tblPJ.length; z++) {

                    let element = _this.state.tblPJ[z];
                    if (!element.isInline) continue
 

                    body = body.replace(new RegExp('"cid:' + element.name + '@(.*)"'), '"' + element.contenu + '" ');

                }


                let email = { ...result.asyncContext, body: body }

                fetch(CONFIG.URL + '/expertise/postEmailfichier', { headers: { 'Token': _this.props.token }, method: "POST", body: JSON.stringify(email) })
                    .then(res => {

                        if (res.status != 201) {
                            res.json().then(data => {
                                _this.props.addToast(data.message, { appearance: 'error', autoDismiss: true });
                                return false;
                            }, () => {
                                _this.props.addToast("Enregistrement impossible", { appearance: 'error', autoDismiss: true });
                            })
                        } else {
                            _this.props.addToast('Email classé avec succès', { appearance: 'success', autoDismiss: true });
                            const insightMessage = {
                                type: Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage,
                                message: "Classé dans GEX le "+moment(new Date()).format('DD/MM/yyyy HH:mm'),
                                icon: "Icon.80x80",
                                persistent:true
                            };
                            
                            // Show notification.
                            Office.context.mailbox.item.notificationMessages.addAsync("gexKey", insightMessage, (result) => {
                                console.log("Added notification:");
                                console.log(result);
                            });
                        }
                    }, error => {
                        _this.props.addToast("Enregistrement impossible", { appearance: 'error', autoDismiss: true });
                    })
            });

        this.state.tblPJ.filter(element => element.aClasser == true).forEach(element => {
            Office.context.mailbox.item.getAttachmentContentAsync(element.id, (valueElement) => {
                if (valueElement.status === Office.AsyncResultStatus.Failed) {
                    _this.props.addToast(asyncResult.error.message, { appearance: 'error', autoDismiss: true });
                    return;
                  }
                let data = {
                    idDs: _this.state.dossierEnCours.dsId,
                    fiNom: element.name,
                    fiContenu: 'data:' + element.contentType + ';base64,' + valueElement.value.content,
                    sousDossier: false
                }
                fetch(CONFIG.URL + '/expertise/postFichier', { headers: { 'Token': this.props.token }, method: "POST", body: JSON.stringify(data) })
                    .then(res => {

                        if (res.status != 201) {
                            res.json().then(data => {
                                _this.props.addToast(data.message, { appearance: 'error', autoDismiss: true });
                                return false;
                            })
                        } else {
                            res.json().then(data => {

                                _this.setState({ tblDataFichierParLot: [..._this.state.tblDataFichierParLot, data] })

                                _this.props.addToast('Classement du fichier ' + element.name + ' réalisé avec succès', { appearance: 'success', autoDismiss: true });
                            }
                            )
                        }
                    },
                        error => {
                            _this.props.addToast("Enregistrement impossible", { appearance: 'error', autoDismiss: true });
                        })
            });
        })

    }

    selectDossier(dossier) {
        this.setState({ dossierEnCours: dossier });
    }


    ModificationParLot() {
        let _this = this;
        if (this.state.openPopupFichierParLot) {
            return (<FicheFichierParLotClass token={this.props.token} onClosed={(e) => { _this.setState({ openPopupFichierParLot: false, tblDataFichierParLot: [] }); }} onValide={(data) => {
                //rafraichir les données des fichiers selectionnés
                _this.setState({ openPopupFichierParLot: false, tblDataFichierParLot: [] })

            }} openPopup={_this.state.openPopupFichierParLot} key={"FicLot"} exid={_this.props.exId} tblData={_this.state.tblDataFichierParLot} />)
        } else return ("");
    }


    render() {

        let _this = this;
        if (this.state.tblPJ && this.state.tblPJ.length > 0 && this.state.tblData) {
            return (<div id="popupClassementBody" className="row mt-2">
                <div>
                    <button type="button" className="btn btn-primary" onClick={this.postclassement}>Classer uniquement email</button>
                </div>
                <div id='email'>
                    <h5 className='mt-2'>Classement des pièces jointes</h5>
                    <table style={{ marginTop: 15 + 'px' }} className="table  table-sm" id="main-table">
                        <thead className="table-dark">
                            <tr>
                                <th style={{ width: '2%' }}></th>
                                <th style={{ width: '70%' }}>Pièces à classer</th>
                                <th style={{ width: '30%' }}>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _this.state.tblPJ.map((element, i) => {
                                    return (
                                        <tr key={'PJ' + i}>
                                            <td>
                                                <input onChange={(e) => _this.setState({ tblPJ: _this.state.tblPJ.map(elePj => elePj == element ? { ...elePj, aClasser: e.target.checked } : elePj) })} type="checkbox" checked={_this.state.tblPJ.find(elePj => elePj == element).aClasser ? "checked" : ""}></input>
                                            </td>
                                            <td>{element.name}</td>
                                            <td>{"Pièce jointe"}</td>
                                        </tr>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </table >


                </div>
                <div id="Classement">
                    <table style={{ marginTop: 15 + 'px' }} className="table  table-sm" id="main-table">
                        <thead className="table-dark">
                            <tr>
                                <th style={{ width: '100%' }}>Dossier de classement</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _this.state.tblData.map((element, i) => {
                                    return (
                                        <Fragment key={"DS" + element.dsId}>
                                            <GedDossierClass token={this.props.token} dossierEnCours={this.state.dossierEnCours} trclick={_this.selectDossier} complet={false} niveau={0} exid={this.props.exId} dossier={element}></GedDossierClass>
                                        </Fragment>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </table >
                    <div className="modal-footer">
                        <button disabled={this.state.dossierEnCours.dsId > 0 && this.state.tblPJ.find(ele => ele.aClasser == true) ? '' : 'disabled'} type="button" className="btn btn-primary" onClick={this.postclassement}>Classer l'email et les pièces jointes</button>
                    </div>
                    {this.ModificationParLot()}
                </div>
            </div>
            )

        } else {
            return (<div className="">
                <button type="button" className="btn btn-primary" onClick={this.postclassement}>Classer l'email dans le dossier</button>
            </div>);
        }


    }
}

export default withToast(ClassementDossierClass)