
import React, { Fragment } from 'react'
import { GrAddCircle,GrMoreVertical } from "react-icons/gr";
import { ToastProvider } from './ToastNotification';
import { FaSpinner, FaPlus, FaRProject } from 'react-icons/fa';
import { MdExpandMore, MdExpandLess, MdChevronRight } from "react-icons/md";
import { FcFolder, FcFile } from "react-icons/fc";
import CONFIG from './config';

class GedDossierClass extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            dossier: this.props.dossier,
            tblFichierEnCours : this.props.tblFichierEnCours,
            dossierEnCours : this.props.dossierEnCours,
            Loading: false,
            complet:this.props.complet
        }
        this.getData = this.getData.bind(this);
        this.chargeSousDossier = this.chargeSousDossier.bind(this);
        this.asSousDossier = this.asSousDossier.bind(this);
        this.asChild = this.asChild.bind(this);
        this.isComplet = this.isComplet.bind(this);
        this.trclick = this.trclick.bind(this);

    }

    componentDidUpdate(prevProps) {

        if (this.props != prevProps) {
            this.setState({  dossier: this.props.dossier, dossierEnCours: this.props.dossierEnCours,tblFichierEnCours:this.props.tblFichierEnCours })
        }
    }

    getData(dsId, i) {

        let _this = this
        fetch(CONFIG.URL+'/expertise/getDossier/' + this.props.exid + '/' + dsId,{ headers : {'Token' : this.props.token}})
            .then(res => res.json())
            .then(
                (result) => {

                    let dossierLocal = _this.state.dossier
                    dossierLocal.sousdossiers[i].sousdossiers = JSON.parse(result);

                    this.setState({ dossier: dossierLocal, Loading: true });
                },
                (error) => {
                    console.log(error);
                }
            )

    }
    isComplet() {
        if (this.state.complet) { return(<Fragment>
            
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td>{""}</td>
                        <td><a name="openMenu" onClick={this.trclick} href="#"><GrMoreVertical></GrMoreVertical></a></td>

                        </Fragment>)
                    }
    }

    trclick(e) {
        if (this.props.trclick) {
            this.props.trclick(this.state.dossier,{},e)
        }

    }


    render() {
        let _this = this;
        
        if (this.state.dossier) {

            return (
                <Fragment key={"DS" + this.state.dossier.dsId}>
                    <tr className={_this.state.dossierEnCours == this.state.dossier ? 'ligneSelect':''} style={{ backgroundColor: _this.state.dossierEnCours == this.state.dossier ? "#e5e5e5" : "" }} onMouseDown={_this.trclick} id={this.state.dossier.dsId}>
                    {this.props.modeSelection ? <td><input type="checkbox"></input></td>: null}
                        <td style={{ paddingLeft: (!_this.asChild() && _this.props.niveau==0 ) ? 20 : 40* _this.props.niveau-(_this.asChild()? 15 :0) + 'px' }}>{this.asSousDossier()}<FcFolder /><span>{this.state.dossier.dsNom}</span> </td>
                        {_this.isComplet()}
                    </tr>
                    {!this.state.Loading ? '' : this.state.dossier.sousdossiers.map((element, i) => {

                        return (
                            <GedDossierClass modeSelection={this.props.modeSelection} tblFichierEnCours={this.state.tblFichierEnCours} trclick={this.props.trclick}  dossierEnCours={this.state.dossierEnCours} complet={_this.props.complet} key={'GDS' + element.dsId} niveau={_this.props.niveau + 1} exid={_this.props.exid} dossier={element} />
                        )
                    })}
                </Fragment>
            )
        }

    }
  

    asChild() {

        if ((this.state.dossier.sousdossiers && this.state.dossier.sousdossiers.length > 0) || (this.props.complet && this.state.dossier.fichiers && this.state.dossier.fichiers.length > 0)) {
            return true;
        } else {
            return false;
        }
    }

    chargeSousDossier(e) {
        e.preventDefault();
        let _this = this;
        if (this.state.Loading) {
            this.setState({ Loading: false });
            return;
        }
        if (this.state.dossier.sousdossiers && this.state.dossier.sousdossiers.length > 0) {
            this.state.dossier.sousdossiers.map((element, i) => {
                _this.getData(element.dsId, i);
            })
        } else {
            this.setState({ Loading: true });
        }
    }


    asSousDossier() {
        if ((this.state.dossier.sousdossiers && this.state.dossier.sousdossiers.length > 0) || (this.props.complet && this.state.dossier.fichiers && this.state.dossier.fichiers.length > 0)) {
            return (<a href="#" onClick={this.chargeSousDossier}>{this.state.Loading ? <MdExpandMore /> : <MdChevronRight />}</a>);
        }
    }



}

export default GedDossierClass
