import { App } from "./components/App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import * as React from "react";
import * as ReactDOM from "react-dom";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './taskpane.css'
import '../../assets/style/icons.css'
/* global document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;

const title = "GEX Cloud pour Outlook";
const render = (Component) => {
    ReactDOM.render(
        <AppContainer>
            <Component title={title} isOfficeInitialized={isOfficeInitialized} />
        </AppContainer>,
        document.getElementById("container")
    );
};




/* Render application after Office initializes */
Office.onReady(() => {
    isOfficeInitialized = true;
    render(App);
});

/* Initial render showing a progress bar */
render(App);

if (module.hot) {
    module.hot.accept("./components/App", () => {
        const NextApp = require("./components/App").default;
        render(NextApp);
    });
}
