import React, { useState, useEffect, Fragment } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { GrCheckboxSelected, GrAddCircle, GrRefresh } from 'react-icons/gr';
import { MdExpandMore, MdChevronRight } from 'react-icons/md';
import { FcFolder, FcFile } from 'react-icons/fc';
import PdfViewer from './PdfViewer';
import moment from 'moment';
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import CONFIG from './config'
import Fetching from './Fetching';

export const ModeleEmail = (props) => {
    const [Loading, setLoading] = useState("");
    const [tblData, settblData] = useState([]);

    const [IdEnCours, setIdEnCours] = useState(0);
   


    useEffect(() => {
        if (tblData.length <= 0) getData();

    }, []);


    useEffect(() => {
        if (IdEnCours>0) {
            let data={
                destinataires:[],
                exId:props.exid,
                emId:IdEnCours
            };
            fetch(CONFIG.URL + '/data/postFusionEmail', { headers: { 'Token': props.token }, method: "POST", body: JSON.stringify(data) })

            .then(res => res.json())
            .then(
                (result) => {
                   let email=JSON.parse(result);
                   Office.context.mailbox.item.body.getAsync("html",(asyncResult)=>{
                    Office.context.mailbox.item.body.setAsync(email.emMessage+asyncResult.value, {coercionType: "html"},function (asyncResult) {
                        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                            
                        } else {
                            console.error(asyncResult.error);
                        }
                        
                    })                 
                })
                  
                    Office.context.mailbox.item.subject.setAsync(email.emSujet, function (asyncResult) {
                        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {

                        } else {
                            console.error(asyncResult.error);
                        }
                        
                    })
                    setLoading(true);
                },
                (error) => {
                    setMessage(null);
                    setLoading(true);
                }
            )

        };

    }, [IdEnCours]);

    function getData() {

        fetch(CONFIG.URL + '/data/getModeleemails', { headers: { 'Token': props.token } })

            .then(res => res.json())
            .then(
                (result) => {
                    settblData(JSON.parse(result));
                    setLoading(true);
                },
                (error) => {
                    settblData(null);
                    setLoading(true);
                }
            )
    }

    return <div>
        <label>Charger un modèle d'email</label>
         <select value={IdEnCours || 0} onChange={(e)=>setIdEnCours(e.target.value)} className="form-select" aria-label=".form-select-lg">
                    <option value={0}>{"<< Choix d'un modèle >>"}</option>
                    {tblData.map((element, i) =>
                    <option key={i} value={element.emId}>{element.emNom}</option>
                )}
            </select>
    </div>



}