
import React, { Fragment } from 'react'
import { FaSpinner } from 'react-icons/fa';
import { useToasts } from './ToastNotification';
import { GrCheckboxSelected } from 'react-icons/gr';
import CONFIG from './config'

function withToast(Component) {
    return function WrappedComponent(props) {
        const addToast = useToasts()
        return <Component {...props} {...addToast} />;
    }
}
class TableIntervenantParTypeClass extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            tblData: [],
            Loading: false,
            tblType: [],
            tblSelect: [],
            tblCategorieMasque: []
        }


        this.getData = this.getData.bind(this);
        this.trclick = this.trclick.bind(this);
        this.clickCategorie = this.clickCategorie.bind(this);
        this.fctInsertEmail = this.fctInsertEmail.bind(this);

        this.getData();

    }



    clickCategorie(element) {
        if (this.state.tblCategorieMasque.find(ele => ele == element)) {
            this.setState({ tblCategorieMasque: this.state.tblCategorieMasque.filter(ele => ele != element) });
        } else {
            this.setState({ tblCategorieMasque: [...this.state.tblCategorieMasque, element] });
        }



    }

    trclick(dossier, fichier, e) {
        let _this = this;
        if (dossier && dossier.dsId) {
            this.setState({ dossierEnCours: dossier, tblFichierEnCours: [] });
        } else {
            if (fichier) {
                let multiSelection = e.ctrlKey;
                if (multiSelection || (this.state.tblFichierEnCours.find(element => element.fiId == fichier.fiId) && e.button == 2)) {
                    let tblFichierEnCours = this.state.tblFichierEnCours;
                    if (!(tblFichierEnCours.find(element => element.fiId == fichier.fiId))) {
                        tblFichierEnCours.push(fichier)
                    } else {
                        if (e.button == 0) {
                            var index = tblFichierEnCours.indexOf(fichier);
                            if (index !== -1) {
                                tblFichierEnCours.splice(index, 1);
                            }

                        }
                    }

                    this.setState({ dossierEnCours: dossier, tblFichierEnCours: tblFichierEnCours });
                } else {
                    this.setState({ dossierEnCours: dossier, tblFichierEnCours: [fichier] });
                }
            }
        }
    }




    getData() {

        fetch(CONFIG.URL + '/expertise/getPartie/' + this.props.exid, { headers: { 'Token': this.props.token } })
            //fetch("/getForum/"+this.props.chaFichier+"/"+ this.props.entIDFIC)
            .then(res => res.json())
            .then(
                (result) => {
                    let data = JSON.parse(result);
                    let i = 0;
                    let tblType = [];
                    for (i = 0; i < data.length; i++) {
                        if (tblType.indexOf(data[i].itType) < 0) {
                            tblType.push(data[i].itType)
                        }
                    }
                    this.setState({ tblData: data, tblType: tblType, Loading: true });
                },
                (error) => {
                    console.log(error);
                    this.setState({ tblData: null, Loading: true });
                }
            )
    }

    fctTypeIntervenant_cha(type) {
        switch (type) {
            case "1":
            case 1: return 'Demandeur';
            case "2":
            case 2: return 'Défendeur';
            case "3":
            case 3: return 'Avocat';
            case "4":
            case 4: return 'Conseil';
            case "5":
            case 5: return 'Sapiteur';
            case "6":
            case 6: return 'Tribunal';
            case "10":
            case 10: return 'Autre tiers';
            default:
                return 'Tiers';
        }
    }

    fctInsertEmail(type) {
        switch (type) {
            case 'a':
                Office.context.mailbox.item.to.setAsync(this.state.tblSelect.filter(ele=> ele.tiers.ctEmail!='').map(ele => ele.tiers.ctEmail), function (asyncResult) {
                    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        console.log("Succeeded in setting To field.");
                    } else {
                        console.error(asyncResult.error);
                    }
                    
                })
                break;
            case 'cc':
                Office.context.mailbox.item.cc.setAsync(this.state.tblSelect.filter(ele=> ele.tiers.ctEmail!='').map(ele => ele.tiers.ctEmail), function (asyncResult) {
                    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        console.log("Succeeded in setting Cc field.");
                    } else {
                        console.error(asyncResult.error);
                    }
                    
                })
                break;
            case 'cci':
                Office.context.mailbox.item.bcc.setAsync(this.state.tblSelect.filter(ele=> ele.tiers.ctEmail!='').map(ele => ele.tiers.ctEmail), function (asyncResult) {
                    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                        console.log("Succeeded in setting Cci field.");
                    } else {
                        console.error(asyncResult.error);
                    }
                    
                })
                break;
            }
            this.setState({tblSelect:[]});
    }

        render() {

            let _this = this;
            if (!this.state.Loading) {
                return (<div key={"SPNN" + this.props.exid} className="">
                    <FaSpinner className="spinner" />
                </div>)
            }

            if (this.props.exid > 0 && this.state.tblData) {
                return (

                    <div className="p-3 col-lg-12" key={"INT" + this.props.exid}>
                        <div>
                            <button onClick={() =>this.fctInsertEmail('a')} style={{ display: (this.state.tblSelect && this.state.tblSelect.length > 0 ? '' : 'none') }} className='btn btn-primary my-1'>A</button>
                            <button onClick={() =>this.fctInsertEmail('cc')} style={{ display: (this.state.tblSelect && this.state.tblSelect.length > 0 ? '' : 'none') }} className='btn btn-primary my-1 mx-1'>Cc</button>
                            <button onClick={() =>this.fctInsertEmail('cci')} style={{ display: (this.state.tblSelect && this.state.tblSelect.length > 0 ? '' : 'none') }} className='btn btn-primary my-1'>Cci</button>
                        </div>
                        <table className="table table-sm" id="main-table">

                            <thead className="table-dark">
                                <tr>
                                    <th style={{ width: '2%' }}><GrCheckboxSelected /></th>
                                    <th style={{ width: '45%' }}>Tiers</th>
                                    <th style={{ width: '20%' }}>Contact</th>
                                    <th style={{ width: '30%' }}>Email</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _this.state.tblType.map((element, i) => {

                                        return (
                                            <Fragment key={i}>
                                                <tr key={"ligneRegroupement" + i} id={element} className='ligneRegroupement'>
                                                    <td><input onChange={(e) => {
                                                        let i = 0;
                                                        let tblSelect = this.state.tblSelect;

                                                        for (i = 0; i < this.state.tblData.length; i++) {
                                                            if (this.state.tblData[i].itType == e.target.parentElement.parentElement.id) {
                                                                if (e.target.checked && tblSelect.findIndex(element => element.itId == this.state.tblData[i].itId) < 0) {
                                                                    tblSelect.push(this.state.tblData[i])
                                                                }
                                                                if (!e.target.checked && tblSelect.findIndex(element => element.itId == this.state.tblData[i].itId) >= 0) {
                                                                    tblSelect.splice(tblSelect.findIndex(element => element.itId == this.state.tblData[i].itId), 1)
                                                                }
                                                            }

                                                        }
                                                        this.setState({ tblSelect: tblSelect });
                                                        if (this.props.onSelect) this.props.onSelect(tblSelect);
                                                    }


                                                    } type="checkbox" /></td>
                                                    <td><a href="#" className={_this.state.tblCategorieMasque.find(ele => ele == element) ? 'ligneOuverte' : 'ligneFerme'} onClick={() => this.clickCategorie(element)}></a>{this.fctTypeIntervenant_cha(element)}</td>
                                                    <td></td>
                                                    <td></td>

                                                </tr>
                                                {
                                                    _this.state.tblData.map((enfant, i) => {

                                                        if (element == enfant.itType && !_this.state.tblCategorieMasque.find(ele => ele == enfant.itType)) {
                                                            return (
                                                                <tr key={enfant.itId} className={'ligneDetailN1 ligneVisible intervenantType' + element}>
                                                                    <td><input onChange={(e) => {
                                                                        let i = 0;
                                                                        let tblSelect = this.state.tblSelect;

                                                                        if (e.target.checked && tblSelect.findIndex(element => element.itId == e.target.id) < 0) {

                                                                            for (i = 0; i < this.state.tblData.length; i++) {

                                                                                if (this.state.tblData[i].itId == e.target.id) {

                                                                                    tblSelect.push(this.state.tblData[i])
                                                                                }
                                                                            }

                                                                        }
                                                                        if (!e.target.checked && tblSelect.findIndex(element => element.itId == e.target.id) >= 0) {
                                                                            tblSelect.splice(tblSelect.findIndex(element => element.itId == e.target.id), 1)
                                                                        }

                                                                        this.setState({ tblSelect: tblSelect })
                                                                        if (this.props.onSelect) this.props.onSelect(tblSelect);
                                                                    }}
                                                                        type="checkbox" id={enfant.itId} checked={this.state.tblSelect.findIndex(e => e.itId == enfant.itId) >= 0 ? "checked" : ""} /></td>
                                                                    <td>{enfant.tiers ? enfant.tiers.ctEstPro ? enfant.tiers.ctIntitule : enfant.tiers.ctNom + ' ' + enfant.tiers.ctPrenom : '' || ''}</td>
                                                                    <td>{(enfant.contact != null) ? enfant.contact.c0Nom + ' ' + enfant.contact.c0Prenom : ''}</td>
                                                                    <td>{(enfant.contact != null && enfant.contact.c0Email != '') ? enfant.contact.c0Email : enfant.tiers.ctEmail}</td>
                                                                </tr>
                                                            );
                                                        } else { return null }
                                                    })
                                                }
                                            </Fragment>
                                        );
                                    }
                                    )
                                }

                            </tbody>
                        </table >
                    </div>

                )
            } else {
                return '';
            }
        }



    }

export default withToast(TableIntervenantParTypeClass)
